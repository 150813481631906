import * as React from 'react';
import { graphql } from 'gatsby';

import { Template, Split, ImageBlock } from 'common/layout';
import { LoginForm } from 'modules/LoginForm';
import LoginHeaderImage from 'images/SaintsandStars-Login.jpg';

const LoginPage: React.FC<PricingPageProps> = ({ location }) => (
  <Template>
    <Split.Container>
      <Split.Content $first $hideOnMobile>
        <ImageBlock
          fullHeight
          logoInclude
          alt="Saints and Stars login flow"
          src={LoginHeaderImage}
        />
      </Split.Content>
      <Split.Content $backgroundColor>
        <LoginForm location={location} />
      </Split.Content>
    </Split.Container>
  </Template>
);

export default LoginPage;

export const query = graphql`
  query Login($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

type PricingPageProps = {
  location: {
    state: {
      redirectUrl: string;
    };
  }
};
