import React from 'react';

import ArrowLeft from 'vectors/arrowLeft.svg';

import { ArrowWrapper, ArrowPositionContainer } from './styled';

export const ArrowContainer: React.FC<ArrowContainerProps> = ({ onClick }) => {
  return (
    <ArrowPositionContainer>
      <ArrowWrapper onClick={onClick}>
        <ArrowLeft />
      </ArrowWrapper>
    </ArrowPositionContainer>
  );
};

type ArrowContainerProps = {
  onClick: () => void;
};
