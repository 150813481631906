import styled from 'styled-components';

import { media } from 'styles/utils';

export const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 24px 0;

  ${media.desktop`
    margin: 0 auto;
    width: 60%;
    padding: unset;
    display: grid;
  `}
`;
